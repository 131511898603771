$afi-white: #fff;
$afi-slate: #566a73;
$afi-pale-slate: #677e89;
$afi-pink: #ea0079;
$afi-mid-pink: #f5c4dc;
$afi-darken-pink: #c40065;
$afi-hover-pink: #ea75af;
$afi-blue: #96c4d8;
$afi-pale-blue: #cbe2ec;
$afi-darken-blue: #79aabe;
$afi-pale-green: #dceae7;
$afi-success-green: #e3f6e8;
$afi-darken-green: #4aa54a;
$afi-beige: #e8e5da;
$afi-pale-pink: #f5e0eb;
$afi-pale-purple: #dee2e6;
$afi-yellow: #f7f0d8;
$afi-darken-yellow: #F5A623;
$afi-pale-yellow: #F5A62333;
$afi-darkest: #212529;
$afi-black: #000000;
$afi-red: #d0021b;
$afi-pale-red: #f7d7db;

$afi-policy-dark-purple: #643e65;
$afi-policy-light-purple: #e5e3f2;
$afi-policy-dark-green: #375e49;
$afi-policy-light-green: #d9ede1;
$afi-policy-dark-blue: #3f5e82;
$afi-policy-light-blue: #dff0f2;
$afi-policy-dark-brown: #69532d;
$afi-policy-light-yellow: #fcf7e9;
$afi-policy-pink: #ea0079;
$afi-policy-light-pink: #f9e3ef;
$afi-policy-dark-teal: #0f5a6c;
$afi-policy-light-teal: #e3eaf6;
$afi-policy-gold: #f6e4b5;

$afi-alert: #cc203b;
$afi-success: #8ab65a;
$afi-renew: #f3a240;

$afi-claims-success: #70be1a;
$afi-claims-waiting: #f3a143;
$afi-claims-received: #3fa9b5;

// Colours to revist / clarfiy
$afi-line-color: #d2d2d2;
$afi-footer-grey: #868e96;

$afi-bg-black: #1a2029; // TODO: to be clarified when we review footer content
$afi-bg-white: #f1f1f1; // TODO: to be removed when we have assets
$afi-bg-light-grey: #E6E6E6;
$afi-bg-dark-grey: #979595;
$shadow: rgba(86, 106, 115, 0.13);
$dark-shadow: rgba(86, 106, 115, 0.8);

@function make-color($color, $alpha) {
  @return scale_color($color, $lightness: 100% - $alpha);
}

@function color-15($color) {
  @return make-color($color, 15%);
}

@function color-30($color) {
  @return make-color($color, 30%);
}

@function color-50($color) {
  @return make-color($color, 50%);
}

@function color-70($color) {
  @return make-color($color, 70%);
}