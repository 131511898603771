details.afi-details>summary {
  color: $afi-pink;
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
}

.disabled-link {
  color: $afi-line-color;
  font-weight: bold;
  cursor: text;
  pointer-events: none;
}