.claims-background {
    background-repeat: repeat;
    background-position: center center;

    &--default {
        background-image: url(/assets/img/claims/default-background.svg);
        background-color: rgba($afi-policy-light-pink, 0.4);
    }

    &--accident-only-cat,
    &--accident-only-dog {
        background-color: rgba($afi-policy-light-blue, 0.4);
    }

    &--accident-only-cat {
        background-image: url(/assets/img/claims/cat-background-accident-only.svg);
    }

    &--accident-only-dog {
        background-image: url(/assets/img/claims/dog-background-accident-only.svg);
    }

    &--lifetime-cat, 
    &--lifetime-dog {
        background-color: rgba($afi-policy-light-purple, 0.4);
    }

    &--lifetime-cat {
        background-image: url(/assets/img/claims/cat-background-lifetime.svg);
    }

    &--lifetime-dog {
        background-image: url(/assets/img/claims/dog-background-lifetime.svg);
    }

    &--max-benefit-cat,
    &--max-benefit-dog {
        background-color: rgba($afi-policy-light-green, 0.4);
    }

    &--max-benefit-cat {
        background-image: url(/assets/img/claims/cat-background-max-benefit.svg);
    }

    &--max-benefit-dog {
        background-image: url(/assets/img/claims/dog-background-max-benefit.svg);
    }

    &--time-limited-cat,
    &--time-limited-dog {
        background-color: rgba($afi-policy-light-yellow, 0.4);
    }

    &--time-limited-cat {
        background-image: url(/assets/img/claims/cat-background-time-limited.svg);
    }

    &--time-limited-dog {
        background-image: url(/assets/img/claims/dog-background-time-limited.svg);
    }
}
